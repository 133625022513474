<template>
    <div style="margin: 40px;">
        <!-- 大屏展示前端东西 -->
        <div>
            <div style=" padding: 50px 50px">
                <el-row>

                    <el-col :span="12">
                        <h1 style="font-size: 3vw; margin: 10px 0px; color: #002fa7">
                            欢迎来到Dawn的摄影作品展
                        </h1>
                        <h1 style="font-size: 2vw">Welcome to Dawn's Photography Exhibition</h1>
                        <p class="myWebIntrooduce" style="margin-top: 20px">
                            Dawn会在这里分享日常拍摄的照片，单色调一直是Dawn的最爱，如果你有什么建议记得告诉我
                        </p>

                        <p class="myWebIntrooduceEnglish" style="margin-top: 10px;">
                            Dawn will be sharing her daily photographs here, and monochrome has always been her
                            favorite. If you have any suggestions, be sure to let me know!
                        </p>
                        <p style="margin-top: 20px" class="myWebIntrooduce">
                            所有作品均使用Nikon Z30拍摄完成
                        </p>
                        <p class="myWebIntrooduceEnglish" style="margin-top: 10px;">
                            All works were captured using the Nikon Z30.
                        </p>
                        <p class="myWebIntrooduce" style="margin-top: 20px">
                            希望Dawn的作品可以带给你全新的感受
                        </p>
                        <p class="myWebIntrooduceEnglish" style="margin-top: 10px;">
                            I hope Dawn's works can bring you a fresh perspective!
                        </p>
                    </el-col>
                    <el-col :span="12" style="padding: 10px 10px">
                        <el-card>
                            <img src="@/views/myPhoto/imgs/dawn.jpg" alt="" style="width: 100%;">
                        </el-card>
                    </el-col>
                </el-row>
                <br>
                <br>
                <!-- 画廊展示 -->
                <el-row type="flex" justify="space-around">
        <el-col :span="7">
          <photoVue :paperText="paperText1" />
        </el-col>
        <el-col :span="7">
          <photoVue :paperText="paperText2" />
          
        </el-col>
        <el-col :span="7">
          <photoVue :paperText="paperText3" />
        </el-col>
      </el-row>

            </div>
        </div>



    </div>
</template>

<script>
import photoVue from "@/views/myPhoto/photoVue";
export default {
    name: 'myPhoto',
    components: {
        photoVue
    },
    data() {
        return {
            paperText1: {
                title: "广州塔",
                imageUrl: "https://www.1ys53p.top/1728442225693.jpg",
                text: "nothing but everything",
                time: "2024-10-06",
            },
            paperText2: {
                title: "香烟",
                imageUrl: "https://www.1ys53p.top/1728442943502.jpg",
                text: "都是奴隶，谈何自由？",
                time: "2024-10-05",
            },
            paperText3: {
                title: "一棵树",
                imageUrl: "https://www.1ys53p.top/1728443061521.jpg",
                text: "因为这张照片，我知道我的cmos脏了",
                time: "2023-10-06",
            },
        }
    }

}
</script>

<style>
.myWebIntrooduce {
    font-family: "阿里妈妈灵动体 VF Thin";
    font-weight: bold;
    color: #202020;
    font-size: 1vw;
}

.myWebIntrooduceEnglish {
    font-family: "阿里妈妈灵动体 VF Thin";
    color: #202020;
    font-size: small;
    font-size: 1vw;
}
</style>