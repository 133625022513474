import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import AI from '@/views/AI'
import home from '@/views/conter'
import myDaily from '@/views/myDaily'
import myPaper from '@/views/myPaper'
import myPhoto from '@/views/myPhoto'
import myDeepSeek from '@/views/AI/DeepSeek'
import myIfan from '@/views/AI/IFAN'

// AI的子路由
import BigModelUse from "@/views/AI/bigModel/BigModelUse"
import DISC_FinLLM from "@/views/AI/bigModel/DISC_FinLLM"
import Huggingface from "@/views/AI/bigModel/Huggingface"
import Instruct_pix2pix from "@/views/AI/bigModel/Instruct_pix2pix"
import Llama_2_7B from "@/views/AI/bigModel/Llama_2_7B"
import MyBigModel from "@/views/AI/bigModel/MyBigModel"
import Qwen_72B_Chat from "@/views/AI/bigModel/Qwen_72B_Chat"
import Qwen_Audio_Chat from "@/views/AI/bigModel/Qwen_Audio_Chat"
import Qwen_Vid_Chat from "@/views/AI/bigModel/Qwen_Vid_Chat"
import StableSR from "@/views/AI/bigModel/StableSR"
import Stable_Diffusion from "@/views/AI/bigModel/Stable_Diffusion"




export default new VueRouter({
    routes: [
        {
            path: "/home", component: home
        },
        {
            path: "/ai", component: AI,
            children:[
                // {path: "/Llama", component: Llama},
                {path: "/BigModelUse", component: BigModelUse},
                {path: "/DISC_FinLLM", component: DISC_FinLLM},
                {path: "/Huggingface", component: Huggingface},
                {path: "/Instruct_pix2pix", component: Instruct_pix2pix},
                {path: "/Llama_2_7B", component: Llama_2_7B},
                {path: "/MyBigModel", component: MyBigModel},
                {path: "/Qwen_72B_Chat", component: Qwen_72B_Chat},
                {path: "/Qwen_Audio_Chat", component: Qwen_Audio_Chat},
                {path: "/Qwen_Vid_Chat", component: Qwen_Vid_Chat},
                {path: "/StableSR", component: StableSR},
                {path: "/Stable_Diffusion", component: Stable_Diffusion},
            ]
        },
        {
            path: "/Daily", component: myDaily
        },
        {
            path: "/Paper", component: myPaper
        },
        {
            path: "/Photo", component: myPhoto
        },
        {
            path: "/DeepSeek", component: myDeepSeek
        },
        {
            path: "/ifan", component: myIfan
        },
        // 路由重定向
        {
            path: '*',
            redirect: "/home"

        }
    ]
})




